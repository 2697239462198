<template>
  <div>
    <CSelect :value.sync="year" size="sm" style="width:100px; position:absolute; top:10px" :options="[2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]"/>
  <CChartBar
    :datasets="defaultDatasets"
    labels="months"
    :options="{ maintainAspectRatio: true }"
  />
  </div>
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'
import { mapGetters } from 'vuex';

export default {
  name: 'CChartBarExample',
  data(){
    return {
      datatarget: [],
      year: new Date().getFullYear()
    }
  },
  components: { CChartBar },
  props: ['targetData'],
  watch:{
    targetData(newVal){
      this.datatarget = [
        newVal.january,
        newVal.february,
        newVal.march,
        newVal.april,
        newVal.may,
        newVal.june,
        newVal.july,
        newVal.august,
        newVal.september,
        newVal.october,
        newVal.november,
        newVal.december,
        ];
    },
    year(newVal){
      this.$store.dispatch('dashboard/fetchChart', newVal);
    }
  },
  computed: {
    ...mapGetters({ 
        chart: "dashboard/chart",
        chart_target: 'dashboard/chart_target',
    }),
    defaultDatasets () {
      if(this.chart){
        var data = [0,0,0,0,0,0,0,0,0,0,0,0];
        for (let i = 0; i < 12; i++) {
          if(this.chart[i]){
            data.splice(this.chart[i].month-1, 1 , parseInt(this.chart[i].data));
          }
        }
      }
      return [
        {
          label: 'Target',
          backgroundColor: '#7F7F7F',
          data: this.datatarget,
        },
        {
          label: 'Sales',
          backgroundColor: '#E0681E',
          data: data,
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('dashboard/fetchChart', this.year);
  }
}
</script>
