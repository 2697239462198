<template>
    <div>
        <CAlert 
        class="widget_popup_amount"
        :show.sync="dismissCountDown"
        fade
        color="success"
        >{{salesAmount.amount}}</CAlert>
    </div>
</template>

<script>

export default{
    props: ["salesAmount"],
    data() {
        return {
            dismissCountDown: 3,
        }
    }
}
</script>
