<template>
  <div>
    <CRow>
      <CCol v-if="$ability.can('read', '6HhP8Uc')" lg="9">
        <CCard>
          <RoleModal v-if="$store.state.permission.permission_settings" elementKey="6HhP8Uc" customClass="cardBtn" actionKey="read" :keyPage="$route.name"/>
          <CCardBody>
            <CButton v-if="$ability.can('create', 'DP4zred')" @click="targetModal = true" style="position:absolute; right:15px; top:0px;" size="sm" class="branding-btn mt-2">SETUP TARGET</CButton>
            <RoleModal v-if="$store.state.permission.permission_settings" elementKey="DP4zred" customClass="chartBtn" actionKey="create" :keyPage="$route.name"/>
            <CChartBarExample
            :targetData="targetData"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol v-if="$ability.can('read', 'sgUKUR1')" lg="3">
        <CCard>
          <RoleModal v-if="$store.state.permission.permission_settings" elementKey="sgUKUR1" customClass="cardBtn" actionKey="read" :keyPage="$route.name"/>
          <CCardHeader class="custom-header">
            <strong>NOTES</strong>
            <div class="card-header-actions">
              <a v-if="$ability.can('create', 'HAY6OnW')" @click="myModal = true"><CIcon class="hover-pointer" name="cil-plus"/></a>
              <RoleModal v-if="$store.state.permission.permission_settings" elementKey="HAY6OnW" customClass="headerBtn" actionKey="create" :keyPage="$route.name"/>
            </div>
          </CCardHeader>
          <CCardBody class="custom-card-padding">
            <div v-if="notes != ''">
              <div v-for="note in notes" :key="note.id" class="reminders-custom">
                <div class="reminder-posted">
                <h6>{{note.message}}</h6><a @click="deleteNote(note)"><CIcon class="hover-pointer" name="cil-trash"/></a>
                </div>
                <div class="reminder-posted">
                  <small>&nbsp;</small>
                  <small>{{ note.datetime }}</small>
                </div>
              </div>
            </div>
            <div v-else class="text-center">
              no notes
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol v-if="$ability.can('read', 'LfrJngJ')" col="12" sm="6" lg="3">
        <CWidgetProgress>
          <RoleModal v-if="$store.state.permission.permission_settings" elementKey="LfrJngJ" customClass="cardBtn" actionKey="read" :keyPage="$route.name"/>
          <div class="h4 m-0">₱{{sales_widget | countTotalSales}}</div>
           <sales-amount-added
           v-for="data in sales_amount"
          :key="data.id"
          :salesAmount="data"
           />
          <div class="card-header-actions">
            <div 
              class="card-header-action position-absolute"
              style="right:5px; top:5px"
            >
              <CSelect
                  size="sm"
                  :value.sync='sales_option'
                  :options="['Today','Week','Month']"
                />
            </div>
          </div>
          <div>Sales</div>
        </CWidgetProgress>
      </CCol>
      <CCol v-if="$ability.can('read', 'bVKX0Qe')" col="12" sm="6" lg="3">
        <CWidgetProgress>
          <RoleModal v-if="$store.state.permission.permission_settings" elementKey="bVKX0Qe" customClass="cardBtn" actionKey="read" :keyPage="$route.name"/>
          <div class="h4 m-0">₱{{expenses_widget | countExpenses}}</div>
          <div class="card-header-actions">
            <div 
              class="card-header-action position-absolute"
              style="right:5px; top:5px"
            >
              <CSelect
                  size="sm"
                  :value.sync="expenses_option"
                  :options="['Today','Week','Month']"
                />
            </div>
          </div>
          <div>Expenses</div>
        </CWidgetProgress>
      </CCol>
      <CCol v-if="$ability.can('read', '9xfpHQg')" col="12" sm="6" lg="3">
        <CWidgetProgress>
          <RoleModal v-if="$store.state.permission.permission_settings" elementKey="9xfpHQg" customClass="cardBtn" actionKey="read" :keyPage="$route.name"/>
          <div class="h4 m-0">{{members_widget | countMembers}}</div>
          <div class="card-header-actions">
            <div 
              class="card-header-action position-absolute"
              style="right:5px; top:5px"
            >
              <CSelect
                  size="sm"
                  :value.sync='member_option'
                  :options="['All', 'Today','Week','Month']"
                />
            </div>
          </div>
          <div>Members</div>
        </CWidgetProgress>
      </CCol>
    </CRow>

    <CModal
      :show.sync="myModal"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">ADD NOTE</h6>
        <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
      </template>
      <CRow>
        <CCol sm="12">
            <CInput
                placeholder="Note"
                v-model="reminder.note_message"
            />
            <v-date-picker
            color="orange"
            v-model="reminder.date"
            mode="datetime"
            is-inline
            is-expanded
            />
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="AddNote" color="success" class="branding-btn">ADD</CButton>
        <CButton @click="myModal = false" color="danger">Cancel</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="targetModal"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">UPDATE TARGET</h6>
        <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
      </template>
      <CRow>
        <CCol sm="12">
            <CInput v-model="form_target.january" label="January"></CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
            <CInput v-model="form_target.february" label="February"></CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
            <CInput v-model="form_target.march" label="March"></CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
            <CInput v-model="form_target.april" label="April"></CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
            <CInput v-model="form_target.may" label="May"></CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
            <CInput v-model="form_target.june" label="June"></CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
            <CInput v-model="form_target.july" label="July"></CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
            <CInput v-model="form_target.august" label="August"></CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
            <CInput v-model="form_target.september" label="September"></CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
            <CInput v-model="form_target.october" label="October"></CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
            <CInput v-model="form_target.november" label="November"></CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
            <CInput v-model="form_target.december" label="December"></CInput>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="UpdateTarget" color="success" class="branding-btn">UPDATE</CButton>
        <CButton @click="targetModal = false" color="danger">Cancel</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import CChartBarExample from './charts/CChartBarExample'
import SalesAmountAdded from './Dashboard/PopUpAmount'
import RoleModal from "../containers/RoleModal"
import { mapGetters } from 'vuex';

export default {
  name: 'Dashboard',
  data(){
    return {
      sales_option: '',
      member_option: '',
      expenses_option: '',
      myModal: false,
      targetModal: false,
      targetData: '',
      sales_amount: [],
      reminder: {
        note_message: '',
        date: new Date()
      },
      form_target: {
        january: '',
        february: '',
        march: '',
        april: '',
        may: '',
        june: '',
        july: '',
        august: '',
        september: '',
        october: '',
        november: '',
        december: '',
      }
    }
  },
  components: {
    CChartBarExample,
    SalesAmountAdded,
    RoleModal
  },
  computed: {
    ...mapGetters({ 
        sales_widget: "dashboard/sales_widget",
        members_widget: "dashboard/members_widget",
        expenses_widget: "dashboard/expenses_widget",
        notes: 'note/notes',
        target: 'target/target'
    }),
  },
  watch: {
    sales_option (newVal) {
      this.$store.dispatch('dashboard/fetchSalesWedgit', newVal);
    },
    member_option (newVal) {
      this.$store.dispatch('dashboard/fetchMemberWedgit', newVal);
    },
    expenses_option (newVal) {
      this.$store.dispatch('dashboard/fetchExpensesWedgit', newVal);
    },
    target(newVal){
      this.targetData = newVal;
    }
  },
  filters: {
        countTotalSales: function (value) {
            if(value){
                var i;
                var sum = 0;
                for (i = 0; i < value.length; i++) {
                    if(value[i].payment){
                        sum += parseInt(value[i].payment.total)
                    }
                }
                return sum;
            }
        },
        countExpenses: function (value) {
          let sum = 0;
          if(value){
            for (let i = 0; i < value.length; i++){
              sum += parseInt(value[i].cost)
            }
          }
          return sum;
        },
        countMembers: function (value){
            if(value){
              return value.length;
            }else{
              return 0;
            }
            
        }
  },
  methods: {
    AddNote(){
      const params = {
          message: this.reminder.note_message,
          date: new Date(this.reminder.date).toLocaleDateString('zh-Hans-CN') + ' ' + new Date(this.reminder.date).toLocaleTimeString('en-GB'),
          location: 'dashboard'
      }
      
      this.myModal = false;
      this.$store.dispatch('note/addNote', params).then(() => {
        
        this.reminder.note_message = '';
        this.reminder.date = new Date();
      });
    
    },
    deleteNote(data){
        const params = {
            id: data.id,
            user_id: data.user_id
        }
        this.$store.dispatch('note/deleteNote', params);
    },
    UpdateTarget(){
      const params = {
          id: this.$store.getters['auth/user'].branch_id,
          form: this.form_target
      }
      this.targetModal = false;
      this.$store.dispatch('target/updateTarget', params);
    },
    
  },
  created(){
    this.$store.dispatch('target/fetchTarget', this.$store.getters['auth/user'].branch_id).then(() => {
      this.form_target = this.target;
      this.targetData = this.target;
    });
    this.$store.dispatch('note/fetchNote', this.$store.getters['auth/user'].id);
    this.$store.dispatch('dashboard/fetchSalesWedgit', 'Today');
    this.$store.dispatch('dashboard/fetchExpensesWedgit', 'Today');
    this.$store.dispatch('dashboard/fetchMemberWedgit', 'All');
  }
}
</script>
<style>
.reminder-posted{
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: space-between;
}
.hover-pointer {
  cursor:pointer;
}
</style>
